import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PatternCanvas from '../../cutsmart/optimization/wo-optimization-pattern';

import { StatusComponent } from '../../../components/common/status-component';
import { LocationSelectText } from '../../../components/tracktrace/location-select';
import { AboveTab } from '../../../components/common/only-mobile';
import {
  TrackTraceViewModeLargeImage,
  TrackTraceViewModeSmallImage,
} from '../../../assets/icons/icon-tracktrace';
import { useDispatch, useSelector } from 'react-redux';
import { updateCgPatternLarge } from '../../../store/cutguide/cgstationstate';

const roundDim = val => {
  return Math.round(val * 10) / 10.0;
};

const PrintIcon = ({ onClick }) => {
  return (
    <div
      className='d-flex align-items-center'
      style={{ width: '32px', height: '32px' }}
      onClick={onClick}
    >
      <svg
        width='12'
        height='13'
        viewBox='0 0 12 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3 0.5H8.29688C8.69531 0.5 9.09375 0.664062 9.375 0.945312L10.0547 1.625C10.3359 1.90625 10.5 2.30469 10.5 2.70312V4.25H9V2.70312L8.29688 2H3V4.25H1.5V2C1.5 1.17969 2.15625 0.5 3 0.5ZM9 8.75H3V9.125V9.5V11H9V9.5V8.75ZM10.5 9.5V11C10.5 11.8438 9.82031 12.5 9 12.5H3C2.15625 12.5 1.5 11.8438 1.5 11V9.5H0.75C0.328125 9.5 0 9.17188 0 8.75V6.5C0 5.67969 0.65625 5 1.5 5H10.5C11.3203 5 12 5.67969 12 6.5V8.75C12 9.17188 11.6484 9.5 11.25 9.5H10.5ZM10.125 7.4375C10.4297 7.4375 10.6875 7.20312 10.6875 6.875C10.6875 6.57031 10.4297 6.3125 10.125 6.3125C9.79688 6.3125 9.5625 6.57031 9.5625 6.875C9.5625 7.20312 9.79688 7.4375 10.125 7.4375Z'
          fill='#006EED'
        />
      </svg>
    </div>
  );
};
const PatternImage = ({
  optimization,
  normalizedPattern,
  currentPart,
  onPartClick,
  pattern_large,
  displayTemplate,
}) => {
  return (
    <Fragment>
      <div className={`col-12 ${!pattern_large ? '' : 'col-md-8'}`}>
        <div
          className='px-2 pt-0'
          style={{
            width: '100%',
            position: 'relative',
            paddingBottom: '0px',
          }}
        >
          <div className='font-weight-bold mb-0 position-relative py-3 px-2'>
            <PatternCanvas
              currentPart={currentPart}
              pattern={normalizedPattern}
              props={{}}
              optimizationType={optimization.optimization_type}
              onPartClick={(part, partType) => {
                if (onPartClick) {
                  onPartClick({ ...part, type: partType });
                }
              }}
              displayTemplate={displayTemplate}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const CurrentlyCuttingParts = ({ parts, onPartSelect }) => {
  return (
    <Fragment>
      <div className='pt-3'>
        <div className='font-rubik' style={{ fontSize: '16px' }}>
          PARTS
        </div>
        <div
          className='rubik-font d-flex  pt-2 pb-2 px-3 '
          style={{
            fontWeight: 'bold',
            color: '#323232',
            fontSize: '12px',
            borderBottom: '1px solid #979797',
          }}
        >
          <div
            className=' px-1  d-flex'
            style={{
              width: '30px',
            }}
          >
            #
          </div>
          <div
            className=' px-1  d-flex'
            style={{
              width: '130px',
            }}
          >
            PANEL
          </div>
          <div
            className=' px-1  d-flex'
            style={{
              width: '130px',
            }}
          >
            STATUS
          </div>
        </div>
        {parts.map((part, index) => {
          const length = roundDim(part.length);
          const width = roundDim(part.width);
          let status = 'PENDING';
          if (part.processed_qty > 0) {
            status = 'COMPLETED';
          } else if (part.rejected_qty > 0) {
            status = 'REJECTED';
          } else {
            status = 'PENDING';
          }
          return (
            <div
              className='d-flex  pt-2 pb-2 px-3 align-items-center cursor-pointer'
              key={part.uuid}
              style={{
                color: '#323232',
                fontSize: '12px',
                borderBottom: '1px solid #DEDEDE',
              }}
              onClick={() => onPartSelect(part)}
            >
              <div
                className=' px-1  d-flex'
                style={{
                  width: '30px',
                }}
              >
                {index + 1}
              </div>
              <div
                className=' px-1  d-flex'
                style={{
                  width: '130px',
                }}
              >
                <div>
                  <div>
                    {length} x {width}
                  </div>
                  <div>{part.type === 'PART' ? part.part_name : 'Offcut'}</div>
                </div>
              </div>
              <div
                className=' px-1  d-flex'
                style={{
                  width: '130px',
                }}
              >
                <StatusComponent
                  statusMap={{
                    IN_PROGRESS: 'YELLOW',
                    PENDING: 'BLUE_DOT',
                    REJECTED: 'RED',
                    COMPLETED: 'GREEN',
                  }}
                  statusText={status}
                  statusKey={status}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};
const CurrentlyCutting = ({
  optimization,
  normalizedPattern,
  parts,
  currentPart,
  setCurrentPart,
  onPrintAndNext,
}) => {
  const [displayPart, setDisplayPart] = useState({});
  useEffect(() => {
    if (!currentPart.uuid) {
      setDisplayPart({});
    } else {
      const parts = [];
      const remnants = [];
      const part = {
        x: 0,
        y: 0,
        length: currentPart.length,
        width: currentPart.width,
        rotated: currentPart.rotated,
        processed_qty: 0,
        rejected_qty: 0,
      };
      if (currentPart.type === 'PART') {
        part.processed_qty = currentPart.processed_qty;
        part.rejected_qty = currentPart.rejected_qty;
        parts.push(part);
      } else {
        remnants.push(part);
      }
      setDisplayPart({
        rotated: currentPart.rotated,
        length: currentPart.length,
        width: currentPart.width,
        parts: parts,
        remnants: remnants,
      });
    }
  }, [currentPart, normalizedPattern]);
  return (
    <Fragment>
      <div className='col-12 col-md-4 h-100'>
        <div
          className='px-2'
          style={{
            paddingTop: '0px !important',
            width: '100%',
            position: 'relative',
            height: 'calc(100vh)',
            overflow: 'scroll',
            paddingBottom: '0px',
          }}
        >
          <div
            className='font-rubik d-flex align-items-center'
            style={{ fontSize: '16px' }}
          >
            <div className='d-flex align-items-center ps-2 cursor-pointer'>
              <PrintIcon
                onClick={() => {
                  onPrintAndNext(currentPart);
                }}
              />
            </div>
            <div>CURRENTLY CUTTING</div>
          </div>
          {currentPart.uuid && (
            <Fragment>
              <div className='d-flex align-items-center py-2'>
                <div
                  className='primary-btn-color'
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#FFC700',
                    borderRadius: '5px',
                  }}
                />
                <div>
                  <div
                    className='primary-btn-color ps-2'
                    style={{ fontSize: '16px' }}
                  >
                    {currentPart.type === 'PART'
                      ? currentPart.part_name
                      : 'OFFCUT'}{' '}
                    - ({roundDim(currentPart.length)} x{' '}
                    {roundDim(currentPart.width)})
                  </div>
                  {currentPart.type === 'PART' && (
                    <div
                      className='primary-btn-color ps-2'
                      style={{ fontSize: '14px' }}
                    >
                      {currentPart.part_code}
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex align-items-center py-2 justify-content-center'>
                <div
                  className='position-relative '
                  style={{ width: '250px', height: '250px' }}
                >
                  <PatternCanvas
                    optimizationType={optimization.optimization_type}
                    pattern={displayPart}
                    largeFont={true}
                  />
                </div>
              </div>
            </Fragment>
          )}
          <CurrentlyCuttingParts
            parts={parts}
            onPartSelect={part => {
              setCurrentPart(part);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};
export const PatternContentFooter = ({
  currentPart,
  cutParts,
  next,
  print,
  onReject,
  onBack,
}) => {
  const dispatch = useDispatch();
  const { pattern_large } = useSelector(state => state.cgstationstate);
  const [hasNext, setHasNext] = useState(false);
  useEffect(() => {
    if (!cutParts || cutParts.length === 0) {
      setHasNext(false);
      return;
    }
    if (
      currentPart &&
      currentPart.uuid &&
      cutParts[`${cutParts.length - 1}`].uuid === currentPart.uuid
    ) {
      setHasNext(false);
      return;
    }
    setHasNext(true);
  }, [currentPart, cutParts, setHasNext]);
  const separator = useMemo(() => {
    return (
      <div
        style={{
          height: '90%',
          width: '1px',
          margin: '8px',
          backgroundColor: '#000',
        }}
      ></div>
    );
  }, []);
  return (
    <Fragment>
      <div
        className='position-absolute d-flex align-items-center width-100-percent bg-white p-3 '
        style={{
          height: '72px',
          bottom: '0px',
          boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
          paddingRight: '32px',
        }}
      >
        <div className='d-flex align-items-center'>
          <AboveTab>
            <div>
              <div className='btn-label text-primary btn-primary-tapped text-button-padded cursor-pointer'>
                <LocationSelectText />
              </div>
            </div>
            <div className='ps-4'>
              <div
                className='d-flex align-items-center pe-2'
                onClick={() => {
                  dispatch(updateCgPatternLarge(pattern_large !== true));
                }}
              >
                {pattern_large ? (
                  <TrackTraceViewModeLargeImage />
                ) : (
                  <TrackTraceViewModeSmallImage />
                )}
              </div>
            </div>
            {separator}
          </AboveTab>
        </div>
        <div className='d-flex w-100 justify-content-end'>
          <div className='btn btn-outline-primary ms-2 me-2' onClick={onBack}>
            Back
          </div>
          {currentPart &&
            currentPart.processed_qty > 0 &&
            currentPart.type === 'PART' && (
              <div className='btn btn-outline-danger' onClick={onReject}>
                Reject
              </div>
            )}

          <div className='btn btn-outline-primary ms-2' onClick={print}>
            Print{hasNext ? ' & Next' : ''}
          </div>

          {hasNext && (
            <div className='btn btn-primary ms-2' onClick={next}>
              Next
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export const PatternContent = ({
  optimization,
  woMat,
  normalizedPattern,
  currentPart,
  cutParts,
  setCurrentPart,
  onPrintAndNext,
  pattern_large,
  displayTemplate,
}) => {
  const pattern = woMat?.patterns?.[0];
  return (
    <Fragment>
      <div
        style={{
          height: pattern_large ? 'calc(100vh - 280px)' : 'calc(100vh - 80px)',
          overflow: 'scroll',
          padding: pattern_large ? '0px' : '0px 64px',
        }}
      >
        <div className='row position-relative' id='pattern-content'>
          {!pattern_large && (
            <div>
              <div
                className='row'
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  paddingLeft: '30px',
                }}
              >
                <div className='col' style={{ width: 'fit-content' }}>
                  <span className='tertiary-text-color'>MATERIAL:</span>
                  <span className='primary-text-color'>{woMat?.material}</span>
                </div>
                <div className='col'>
                  <span className='tertiary-text-color'>
                    GRAIN:{' '}
                    <span className='primary-text-color'>
                      {pattern?.grain > 0 ? 'YES' : 'NO'}
                    </span>
                  </span>
                  <span
                    className='tertiary-text-color'
                    style={{ paddingLeft: '10px' }}
                  >
                    DIM:{' '}
                  </span>
                  <span className='primary-text-color'>
                    {pattern?.length} x {pattern?.width}
                  </span>
                </div>
              </div>
              <div
                className='row'
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  paddingLeft: '30px',
                  paddingTop: '5px',
                }}
              >
                <div className='col' style={{ width: 'fit-content' }}>
                  <span className='tertiary-text-color'>REMARKS:</span>
                  <span className='primary-text-color'>{pattern?.comment}</span>
                </div>
                <div className='col'>
                  <span className='tertiary-text-color'>
                    PRESSED LOCATION:
                    <span className='primary-text-color'>
                      {pattern?.pressed_location}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}

          <PatternImage
            optimization={optimization}
            normalizedPattern={normalizedPattern}
            currentPart={currentPart}
            onPartClick={part => {
              setCurrentPart(part);
            }}
            pattern_large={pattern_large}
            displayTemplate={displayTemplate}
          />
          {pattern_large && (
            <CurrentlyCutting
              optimization={optimization}
              normalizedPattern={normalizedPattern}
              parts={cutParts}
              currentPart={currentPart}
              setCurrentPart={part => {
                setCurrentPart(part);
              }}
              onPrintAndNext={onPrintAndNext}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
