import React, { Fragment, useEffect, useState } from 'react';
import { StatusComponent } from '../../../components/common/status-component';
import { CutGuideWoCardStat } from '../common';
import { MaterialImage } from '../../cutsmart/common';

const CutGuideWoMatCard = ({ woMat, onCardClick }) => {
  const [matStatus, setMatStatus] = useState('PENDING');
  useEffect(() => {
    if (woMat.processed_qty >= woMat.total_qty) {
      setMatStatus('COMPLETED');
    } else if (woMat.processed_qty > 0 && woMat.total_qty > 0) {
      setMatStatus('IN_PROGRESS');
    } else {
      setMatStatus('PENDING');
    }
  }, [woMat, setMatStatus]);
  return (
    <Fragment>
      <div
        className={`position-relative track-trace-card-wrp overflow-hidden`}
        style={{ animationDuration: '1s', overflow: 'hidden' }}
        onClick={onCardClick}
      >
        <div className='d-flex'>
          <div style={{ width: '32px' }}>
            <MaterialImage material={woMat} onClick={null} />
          </div>
          <div className='w-100'>
            <div
              className='track-trace-part-name'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {woMat.material}
              </div>
              <div style={{ marginLeft: '10px', flexShrink: 0 }}>
                {woMat.material_index}
              </div>
            </div>

            <div className='rubik-font pt-3 pb-1 d-flex'>
              <CutGuideWoCardStat
                statCount={woMat.processed_board_qty}
                totalCount={woMat.total_board_qty}
                label={'BOARDS'}
              />
              <CutGuideWoCardStat
                statCount={woMat.processed_qty}
                totalCount={woMat.total_qty}
                label={'PARTS'}
              />
            </div>
            <div className='pb-2'>
              <div className='d-flex justify-content-end'>
                <StatusComponent
                  statusMap={{
                    IN_PROGRESS: 'YELLOW',
                    PENDING: 'BLUE_DOT',
                    COMPLETED: 'GREEN',
                  }}
                  statusText={matStatus}
                  statusKey={matStatus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const WoMaterialListContainer = ({ woMaterials, term, onCardClick }) => {
  const [filteredMaterials, setFilteredMaterials] = useState(woMaterials);
  useEffect(() => {
    setFilteredMaterials(
      woMaterials.filter(
        val =>
          !term ||
          term.length === 0 ||
          val.material.toLowerCase().indexOf(term.toLowerCase()) >= 0,
      ),
    );
  }, [term, woMaterials, setFilteredMaterials]);
  return (
    <Fragment>
      <div className='row py-2'>
        {filteredMaterials.map(woMat => {
          return (
            <div
              className='col-12 col-md-6 col-lg-6 col-xl-4'
              key={woMat.uuid}
              style={{ minWidth: '280px' }}
            >
              <CutGuideWoMatCard
                onCardClick={() => onCardClick(woMat)}
                woMat={woMat}
              />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default WoMaterialListContainer;
