import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '../../../assets/icons/icon-menu.svg';
import Back from '../../../assets/icons/backBtn.svg';
import ShipmentIcon from '../../../assets/icons/icon-shipment.svg';
import { toggleSideMenu } from '../../../store/app/nav/actions';
import useParentWidth from '../../../hooks/useParentWidth';
import OnlyMobile from '../../../components/common/only-mobile';

import { OrgLogo } from '../../../components/org/org-logo';
import { StationSelector } from '../common';
//const CUT_GUIDE_HOME = '/app/cutguide/home';
/* eslint-disable sonarjs/cognitive-complexity */
import { setCgPrinter } from '../../../store/cutguide/cutguide';
import { getUserCustomConfig } from '../../../services/user';
import {
  getPrinters,
  PrinterSelector,
} from '../../order/labelprint/PrinterSelector';
const PatternHeader = ({
  currentStation,
  onClickMenu,
  history,
  location,
  onBack,
  label,
  onPrintAllCb,
}) => {
  const dispatch = useDispatch();

  const container = useRef();

  // eslint-disable-next-line
  const [scrollY, setScrollY] = useState(0);
  const [printers, setPrinters] = useState([]);
  const { printer } = useSelector(state => state.cutguide);
  const [selectedPrinter, setSelectedPrinter] = useState({});
  const { pattern_large } = useSelector(state => state.cgstationstate);
  const handleSideMenuToggle = useCallback(() => {
    dispatch(toggleSideMenu());
  }, [dispatch]);

  const onClick = useCallback(() => {
    if (onClickMenu) {
      onClickMenu();
    } else {
      history.push('/app/cutguide/home');
    }
  }, [history, onClickMenu]);

  const [selectionMode, setSelectionMode] = useState();
  useEffect(() => {
    const fn = async () => {
      const resp = await getUserCustomConfig(
        'USER_CONFIG',
        'PRESSING_SELECTION_MODE',
      );
      if (resp.status === 200 && resp.payload.config_val) {
        const mode = resp.payload.config_val.val || 'PRINT';
        setSelectionMode(mode);
      }
    };
    if (!selectionMode) {
      fn();
    }
  }, [selectionMode, setSelectionMode]);
  useParentWidth(container);

  useEffect(() => {
    const onScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setScrollY]);
  useEffect(() => {
    const defaultPrinter = printers.filter(val => val.selected)[0] || {};
    const selectedPrinter =
      printers.filter(
        val => printer && val.printer_id === printer.printer_id,
      )[0] || {};
    setSelectedPrinter(selectedPrinter || defaultPrinter);
  }, [printer, printers, setSelectedPrinter]);
  useEffect(() => {
    const fn = async () => {
      if (printers && printers.length > 0) {
        return;
      }
      const p = await getPrinters(dispatch, true);
      const mappedPrinters = p.map(val => {
        if (
          printer &&
          printer.printer_id === val.printer_id &&
          val.org_id === printer.org_id
        ) {
          val.selected = true;
        } else {
          val.selected = false;
        }
        return val;
      });
      setPrinters(mappedPrinters);
      setSelectedPrinter(
        mappedPrinters.filter(val => val.selected)[0] ||
          mappedPrinters[0] ||
          {},
      );
    };
    fn();
  }, [dispatch, printers, setPrinters, printer, setSelectedPrinter]);
  return (
    <div
      ref={container}
      className={`flex-row header navbar-expand-lg d-flex p-3 py-4 pt-3 `}
      style={{ zIndex: 10 }}
    >
      {!onBack && (
        <button
          className='p-0 border-0 '
          style={{ backgroundColor: 'transparent' }}
          data-toggle='offcanvas'
          onClick={handleSideMenuToggle}
          type='button'
        >
          <img src={Menu} alt='Menu' />
        </button>
      )}
      {onBack && (
        <button
          className='p-0 border-0 '
          style={{ backgroundColor: 'transparent', minWidth: '24px' }}
          data-toggle='offcanvas'
          onClick={onBack}
          type='button'
        >
          <img src={Back} alt='Back' />
        </button>
      )}
      <div
        className='page-header cursor-pointer d-flex align-items-center'
        onClick={onClick}
      >
        <div className='page-title d-flex align-items-center'>
          <OnlyMobile>
            <img src={ShipmentIcon} alt='' className='ps-2' />
          </OnlyMobile>
          <div className='ps-2 d-flex align-items-center'>
            {label
              ? label
              : currentStation
                ? currentStation.station_name
                : 'Cut Guide'}
          </div>
          <div className='ps-2 d-flex align-items-center'>
            <StationSelector history={history} location={location} />
          </div>
        </div>
      </div>
      <div className='ms-auto d-flex justify-content-end'>
        <div className='pe-3 d-flex align-items-center'>
          {!pattern_large && (
            <div className=''>
              {selectionMode === 'PRINT' && (
                <PrinterSelector
                  printers={printers}
                  onPrintCb={printer => {
                    dispatch(
                      setCgPrinter({
                        printer_id: printer.printer_id,
                        org_id: printer.org_id,
                        name: printer.name,
                      }),
                    );
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className='px-3 py-2 d-flex'
                    style={{ paddingTop: '8px' }}
                  >
                    <div
                      className={
                        'text-nowrap select-printer-text overflow-hidden text-overflow-ellipses'
                      }
                      style={{ maxWidth: '120px' }}
                    >
                      {selectedPrinter
                        ? selectedPrinter.name
                        : 'Select a Printer'}
                    </div>
                  </div>
                </PrinterSelector>
              )}
            </div>
          )}
          <div
            className='btn btn-link '
            style={{ padding: '8px !important' }}
            onClick={onPrintAllCb}
          >
            <div className='d-flex'>
              <div className=''>Print All Labels</div>
            </div>
          </div>
        </div>

        <div
          className='brang-logo ms-auto cursor-pointer d-flex align-items-center'
          onClick={onClick}
        >
          <OrgLogo />
        </div>
      </div>
    </div>
  );
};

PatternHeader.prototype = {
  onClickMenu: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  pipelineMode: PropTypes.bool,
  pipeline_type: PropTypes.string,
  scanState: PropTypes.string,
};

export default PatternHeader;
