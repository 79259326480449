/* eslint-disable sonarjs/cognitive-complexity */
import {
  canItemPackInBox,
  getOrderItemBoxNos,
  isItemBox,
  toastError,
} from './packingHelper';
import {
  getAssemblyParentItemId,
  isBoxItem,
  isSerializedItem,
} from './itemsHelper';
import { cloneDeep } from 'lodash';
import { createOrAddNewBox } from '../../services/packing';
import { deleteBox } from '../../store/packing/itemslist/actions';
import { closeBox } from '../../store/packing/boxAndItems/actions';
import { getState } from '../../store';

const getCurrentBoxItemMap = itemsList => {
  const currentBoxItems = {};
  itemsList.fetchBoxItems.forEach(val => {
    if (!val.box || val.deleted === true) return;
    if (!currentBoxItems[val.box_item_id + '']) {
      currentBoxItems[val.box_item_id + ''] = {
        packedQty: 0,
        items: [],
        boxes: [],
      };
    }
    currentBoxItems[val.box_item_id + ''].packedQty += val.qty;
    currentBoxItems[val.box_item_id + ''].items.push(val);
    currentBoxItems[val.box_item_id + ''].boxes.push(val.box.uuid);
  });
  return currentBoxItems;
};

const getCurrentBoxItemCount = (itemsList, boxId) => {
  let itemCount = 0;
  const currentBoxItems = getCurrentBoxItemMap(itemsList);
  itemsList.items.forEach(val => {
    if (val.deleted === true) return;
    if (!currentBoxItems[val.uuid + '']) return;
    if (currentBoxItems[val.uuid + ''].boxes.indexOf(boxId) === -1) return;
    const currentBoxItem = currentBoxItems[val.uuid + ''];
    if (isSerializedItem(val) || currentBoxItem.packedQty >= val.qty) {
      itemCount++;
    }
  });
  return itemCount;
};
export const getCurrentBoxItemTotalCount = (box, itemsList) => {
  if (!box) {
    return 0;
  }
  if (isItemBox(box)) {
    const boxItem = itemsList.boxesItems.find(
      val => val.deleted === false && val.code === box.code,
    );
    if (!boxItem) {
      return 0;
    }
    const calculateAssemblyCount = (boxItem, assemblyQty) => {
      let _itemCount = 0;
      const boxAssemblyQty = !isSerializedItem(boxItem)
        ? 1
        : boxItem.assembly_qty;
      if (isBoxItem(boxItem)) {
        boxItem.assemblies.forEach(val => {
          _itemCount += calculateAssemblyCount(
            val,
            boxAssemblyQty * assemblyQty,
          );
        });
      } else {
        if (!isSerializedItem(boxItem)) {
          //debugger;
        }
        _itemCount += boxAssemblyQty * assemblyQty;
      }
      return _itemCount;
    };
    let itemCount = 0;
    boxItem.item_definition.assemblies.forEach(val => {
      itemCount += calculateAssemblyCount(val, 1);
    });
    return itemCount;
  } else {
    const currentBoxItems = getCurrentBoxItemMap(itemsList);
    const currentBoxItemUuids = itemsList.fetchBoxItems
      .filter(val => val.deleted === false && val?.box?.uuid === box.uuid)
      .map(val => val.box_item_id);
    const packingGroups = itemsList.items
      .filter(
        val =>
          val.deleted === false && currentBoxItemUuids.indexOf(val.uuid) >= 0,
      )
      .map(val => val.packing_group)
      .filter(val => val && val !== '' && val.trim() !== '');
    let itemCount = 0;
    itemsList.items.forEach(val => {
      if (val.deleted === true) return;
      const packedQty = currentBoxItems[val.uuid]
        ? currentBoxItems[val.uuid].packedQty
        : 0;
      const _itemCount = !isSerializedItem(val) && packedQty === 0 ? 0 : 1;
      if (currentBoxItemUuids.indexOf(val.uuid) >= 0) {
        itemCount += _itemCount;
        return;
      }
      if (box.editable === false) {
        return;
      }
      if (
        packingGroups.indexOf(val.packing_group) >= 0 &&
        val.packing_group &&
        val.packing_group.trim() !== '' &&
        packedQty < val.qty
      ) {
        // eslint-disable-next-line
        itemCount += _itemCount;
      }
    });
    return itemCount;
  }
};
const getCurrentBoxBoxCount = (itemsList, boxId) => {
  let itemCount = 0;
  itemsList.fetchBoxItems.forEach(val => {
    if (!val.box || val?.box?.uuid !== boxId) return;
    if (val.box_item_type === 'BOX') {
      itemCount += val.qty;
      itemCount += getCurrentBoxBoxCount(itemsList, val.box_item_id);
    }
  });
  return itemCount;
};
const getItemUOM = item => {
  if (!item) return '';
  if (!item.item_definition) return '';
  if (!item.item_definition.definition) return '';
  if (!item.item_definition.definition.UOM) return '';
  if (!item.item_definition.definition.UOM.val) return '';
  return item.item_definition.definition.UOM.val;
};
/* eslint-disable  sonarjs/no-identical-functions */
const preparePackingGroupCheckData = box => {
  const currentBoxPackedItems = [];
  //const packedItems = [];
  const packedItemBoxMap = {};
  getState().itemslist.fetchBoxItems.forEach(val => {
    if (val.deleted === true || !val.box || !box) return;
    if (val.box.uuid === box.uuid) {
      currentBoxPackedItems.push(val);
    }
    //packedItems.push(val);
    if (!packedItemBoxMap[val.box_item_id + '']) {
      packedItemBoxMap[val.box_item_id + ''] = {
        packedQty: 0,
        totalItemQty: 0,
        boxItems: [],
      };
    }
    packedItemBoxMap[val.box_item_id + ''].packedQty += val.qty;
    packedItemBoxMap[val.box_item_id + ''].boxItems.push(val);
  });
  getState().itemslist.items.forEach(val => {
    if (packedItemBoxMap[val.uuid + '']) {
      packedItemBoxMap[val.uuid + ''].totalItemQty += val.qty;
    }
  });
  const packedItemIds = currentBoxPackedItems.map(val => val.box_item_id);
  const currentBoxItems = getState().itemslist.items.filter(val => {
    let returnVal = false;
    if (val.deleted === true || isBoxItem(val)) {
      returnVal = false;
    } else if (packedItemIds.indexOf(val.uuid) >= 0) {
      returnVal = true;
    }
    return returnVal;
  });
  const itemBoxMap = {};
  getState().itemslist.boxesItems.forEach(val => {
    itemBoxMap[`${val.code}`] = val;
  });
  return {
    currentBoxPackedItems: currentBoxPackedItems,
    packedItemBoxMap: packedItemBoxMap,
    currentBoxItems: currentBoxItems,
    itemBoxMap: itemBoxMap,
  };
};
/* eslint-disable  sonarjs/no-identical-functions */
export const getCurrentBoxStatus = (itemsList, openedBoxId) => {
  //const start = new Date().getTime();
  const currentBoxItemMap = {};
  itemsList.items.forEach(val => {
    if (val.deleted === true) return;
    if (currentBoxItemMap[`${val.uuid}`] === undefined) {
      currentBoxItemMap[`${val.uuid}`] = {
        uuid: val.uuid,
        exists: false,
        boxItems: [],
        boxCodes: [],
        items: [],
        qty: 0,
        packedQty: 0,
        totalQty: 0,
        currentBoxQty: 0,
      };
    }
    currentBoxItemMap[`${val.uuid}`].totalQty += val.qty;
    currentBoxItemMap[`${val.uuid}`].items.push(val);
  });
  //console.log('Time Taken : 1::' + (new Date().getTime() - start));
  itemsList.fetchBoxItems.forEach(val => {
    if (val.deleted === true || !val.box) return;
    if (currentBoxItemMap[val.box_item_id] === undefined) {
      currentBoxItemMap[val.box_item_id] = {
        exists: false,
        boxItems: [],
        boxCodes: [],
        qty: 0,
        packedQty: 0,
        totalQty: 0,
        currentBoxQty: 0,
      };
    }
    if (val?.box?.uuid === openedBoxId) {
      currentBoxItemMap[val.box_item_id].exists = true;
      currentBoxItemMap[val.box_item_id].boxItems.push(val);
      currentBoxItemMap[val.box_item_id].qty += val.qty;
      currentBoxItemMap[val.box_item_id].currentBoxQty += val.qty;
    }
    currentBoxItemMap[val.box_item_id].boxCodes.push(val.box.code);
    currentBoxItemMap[val.box_item_id].packedQty += val.qty;
  });
  //console.log('Time Taken : 2::' + (new Date().getTime() - start));
  const currentBox = itemsList.fetchBoxes.find(val => val.uuid === openedBoxId);
  const currentBoxBoxes = [];
  const currentBoxItems = itemsList.fetchBoxItems.filter(
    val => val.box && val.box.uuid === openedBoxId && val.deleted === false,
  );
  //console.log('Time Taken : 3::' + (new Date().getTime() - start));
  const currentBoxOrderItems = [];
  const currentBoxItemCount = getCurrentBoxItemCount(itemsList, openedBoxId);
  //console.log('Time Taken : 4::' + (new Date().getTime() - start));
  const currentBoxBoxCount = getCurrentBoxBoxCount(itemsList, openedBoxId);
  //console.log('Time Taken : 5::' + (new Date().getTime() - start));
  const currentBoxPendingItems = [];
  const nonSerialzedItemCapacity = {};
  const currentBoxItemTotalCount = getCurrentBoxItemTotalCount(
    currentBox,
    itemsList,
  );
  //console.log('Time Taken : 6::' + (new Date().getTime() - start));
  const canPackPreparedData = preparePackingGroupCheckData(currentBox);
  //console.log('Time Taken : 6.1::' + (new Date().getTime() - start));
  itemsList.items.forEach(val => {
    if (val.deleted === true || !openedBoxId) return false;
    if (currentBoxItemMap[val.uuid] && currentBoxItemMap[val.uuid].exists) {
      currentBoxOrderItems.push(val);
      return;
    }

    const mappedItem = currentBoxItemMap[val.uuid];
    const pendingQty = mappedItem.totalQty - mappedItem.packedQty;
    if (pendingQty <= 0) {
      return;
    }
    try {
      const isSerialized = isSerializedItem(val);

      //console.log('Time Taken : 6.2::' + (new Date().getTime() - start));
      const canPack = canItemPackInBox(val, currentBox, 1, canPackPreparedData);
      //console.log('Time Taken : 6.3::' + (new Date().getTime() - start));
      if (canPack.type === 'success') {
        if (!isSerialized) {
          canPack.item = val;
          //canPack.totalItemQty = val.qty;
          canPack.totalItemQty = pendingQty;
          //const nonSerializedKey = val.order_src_ref_id + '-' + val.item_id;
          nonSerialzedItemCapacity[val.uuid] = canPack;
        }
        //If it is a Box Item Check if the Assembly parent Matches
        const itemBox = canPackPreparedData.itemBoxMap[`${currentBox.code}`];
        if (itemBox !== undefined) {
          const assemblyParentItemId = getAssemblyParentItemId(
            val,
            itemsList.items,
            itemsList.boxesItems,
          );
          if (assemblyParentItemId === undefined) {
            return;
          }
          if (itemBox.item_id === assemblyParentItemId) {
            currentBoxPendingItems.push(val);
          }
        } else {
          if (currentBox.editable === true) {
            currentBoxPendingItems.push(val);
          }
        }
      }
    } catch (err) {
      //console.log(err);
    }
  });
  //console.log('Time Taken : 7::' + (new Date().getTime() - start));
  const currentBoxProjectedItems = [];
  const projectedItemMap = {};
  const consolidateProjectedItems = (val, packed) => {
    const isSerialized = isSerializedItem(val);
    if (!isSerialized) {
      //debugger;
    }

    const key = isSerialized
      ? 'SERIALIZED:' + val.uuid
      : 'NONSERIALIZED:' + val.uuid;
    let projectedItems = {
      name: isSerialized ? val.code : val.item_name,
      key: key,
      serialized: isSerializedItem(val),
      items: [],
      packedQty: 0,
      totalQty: 0,
      currentBoxQty: 0,
      checklistPending: 0,
      checklistTotal: 0,
      uom: getItemUOM(val),
    };
    if (projectedItems.serialized && packed) {
      projectedItems.packedQty = val.qty;
      projectedItems.totalQty = val.qty;
    } else if (projectedItems.serialized) {
      projectedItems.totalQty = val.qty;
    }
    if (!projectedItemMap[key + '']) {
      projectedItemMap[key + ''] = projectedItems;
    } else {
      projectedItems = projectedItemMap[key + ''];
    }
    projectedItems.items.push(val);
    if (val.operation_checklist) {
      projectedItems.checklistPending += val.operation_checklist.filter(
        val => !val.completed && val.mandatory === true,
      ).length;
      projectedItems.checklistTotal += val.operation_checklist.length;
    }
    if (!projectedItems.serialized && !nonSerialzedItemCapacity[val.uuid]) {
      try {
        if (packed) {
          //debugger;
        }
        const canPack = canItemPackInBox(val, currentBox, 1);
        if (canPack.type === 'success') {
          canPack.item = val;
          nonSerialzedItemCapacity[val.uuid] = canPack;
        } else if (packed) {
          const pendingQty = canPack.totalItemQty - canPack.packedQty;
          if (pendingQty <= 0) {
            canPack.item = val;
            canPack.type = 'success';
            nonSerialzedItemCapacity[val.uuid] = canPack;
          }
        }
      } catch (err) {}
    }
    if (!projectedItems.serialized && nonSerialzedItemCapacity[val.uuid]) {
      projectedItems.packedQty = nonSerialzedItemCapacity[val.uuid].packedQty;
      projectedItems.totalQty = nonSerialzedItemCapacity[val.uuid].totalItemQty;
    }
  };
  //console.log('Time Taken : 8::' + (new Date().getTime() - start));
  currentBoxOrderItems.forEach(val => consolidateProjectedItems(val, true));
  currentBoxPendingItems.forEach(val => consolidateProjectedItems(val, false));
  //console.log('Time Taken : 9::' + (new Date().getTime() - start));
  for (let key in projectedItemMap) {
    if (!projectedItemMap[key + '']) continue;
    const item = projectedItemMap[key + ''];
    let _totalQty = 0;
    let _currentBoxQty = 0;
    let _totalPackedQty = 0;
    item.items.forEach(val => {
      const _currentBoxItem = currentBoxItemMap[val.uuid];
      if (_currentBoxItem && _currentBoxItem.exists) {
        _currentBoxQty += _currentBoxItem.currentBoxQty;
      }
      if (_currentBoxItem) {
        _totalQty = _currentBoxItem.totalQty;
        _totalPackedQty = _currentBoxItem.packedQty;
      }
    });
    //this is needed to ensure that total qty for non serialzed is subtracing the qty already packed
    item.totalQty = _totalQty - _totalPackedQty + _currentBoxQty;
    item.currentBoxQty = _currentBoxQty;
    item.totalItemQty = _totalQty;
    currentBoxProjectedItems.push(item);
  }
  //console.log('Time Taken : 10::' + (new Date().getTime() - start));
  currentBoxProjectedItems.sort((a, b) => {
    if (a.serialized === b.serialized) {
      return a.key.localeCompare(b.key);
    }
    return a.serialized ? -1 : 1;
  });
  //console.log('Time Taken : 11::' + (new Date().getTime() - start));
  return {
    currentBoxItemMap: currentBoxItemMap,
    currentBox: currentBox,
    currentBoxBoxes: currentBoxBoxes,
    currentBoxItems: currentBoxItems,
    currentBoxOrderItems: currentBoxOrderItems,
    currentBoxPendingItems: currentBoxPendingItems,
    currentBoxItemCount: currentBoxItemCount,
    currentBoxItemTotalCount: currentBoxItemTotalCount,
    currentBoxBoxCount: currentBoxBoxCount,
    currentBoxProjectedItems: currentBoxProjectedItems,
  };
};

export const closeCurrentBox = () => async (dispatch, getState) => {
  const itemsList = getState().itemslist;
  const boxAndItems = getState().boxAndItems;
  const currentBoxStatus = getCurrentBoxStatus(
    itemsList,
    boxAndItems.openedBoxId,
  );
  if (!currentBoxStatus) {
    return;
  }
  const currentBox = currentBoxStatus.currentBox;
  if (!currentBox) {
    return;
  }
  const orderItemBoxes = getOrderItemBoxNos(itemsList.items);
  const currentBoxItems = currentBoxStatus.currentBoxItems;
  const currentBoxItemCount = currentBoxStatus.currentBoxItemCount;
  const currentBoxItemTotalCount = currentBoxStatus.currentBoxItemTotalCount;
  const _currentBoxItems = currentBoxItems.filter(val => val.deleted === false);
  if (
    _currentBoxItems.length === 0 &&
    currentBoxItemCount === 0 &&
    currentBoxItemTotalCount === 0 &&
    !isItemBox(currentBox) &&
    orderItemBoxes.indexOf(currentBox.box_index) === -1
  ) {
    const _currentBox = cloneDeep(currentBox);
    _currentBox.deleted = true;
    const boxDeleteResp = await createOrAddNewBox(_currentBox);
    if (boxDeleteResp.status === 200) {
      dispatch(deleteBox(_currentBox.uuid));
    } else {
      console.log(boxDeleteResp);
      toastError('Unable to Close the Box. Please retry');
      return;
    }
  }
  dispatch(closeBox());
};
