import { getState } from '../store';
import service from './index';
const userNotFound = () => {
  return new Promise(resolve => {
    resolve({ status: 1002, message: 'User not logged in' });
  });
};
export const getWorkOrders = filterReq => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org, branch } = user;
  filterReq.req_payload.branch_id = [branch.branch_id];

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/filter`,
    data: filterReq,
  });
};

export const updateWorkOrder = payload => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org, branch } = user;
  payload.branch_id = branch.branch_id;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/create`,
    data: {
      deleted: false,
      approved: true,
      active: true,
      req_payload: payload,
    },
  });
};

export const createWorkOrder = payload => {
  //alert(payload.orderId);
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org, branch } = user;
  payload.branch_id = branch.branch_id;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/create/src/order/${payload.order_id}`,
    data: {
      deleted: false,
      approved: true,
      active: true,
      req_payload: payload,
    },
  });
};

export const releaseWorkOrder = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/release/${woId}`,
    data: {},
  });
};

export const recallWorkOrder = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/recall/${woId}`,
    data: {},
  });
};

export const syncPressingItems = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/syncorderitems/${woId}`,
    data: {},
  });
};

export const getWorkOrderCtxList = filterReq => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org, branch } = user;
  filterReq.req_payload.branch_id = [branch.branch_id];

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorderctx/filter`,
    data: filterReq,
  });
};

export const getWorkOrderCtxItems = woCtxId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorderctx/id/${woCtxId}/orderitems`,
    data: {},
  });
};

export const updateWorkOrderCtx = payload => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorderctx/create`,
    data: {
      deleted: false,
      approved: true,
      active: true,
      req_payload: payload,
    },
  });
};

export const deleteWorkOrderCtx = woCtxId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'DELETE',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorderctx/id/${woCtxId}`,
    data: {},
  });
};

export const getWorkOrderMaterialStocks = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org, branch } = user;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/csstock/filter`,
    data: {
      page: 0,
      size: 10000,
      req_payload: {
        deleted: [false],
        wo_id: [woId],
        branch_id: [branch.branch_id],
        fetch_item: true,
        //merge_wo_material: true,
      },
      sort: [
        { column: 'material', asc: true },
        { column: 'id', asc: true },
      ],
    },
  });
};

export const updateWorkOrderMaterialStock = (stock, propagationType, woId) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const _stock = { ...stock };
  const { org, branch } = user;
  _stock.branch_id = branch.branch_id;
  _stock.reference_uuid = _stock.uuid;
  delete _stock.id;
  delete _stock.uuid;
  delete _stock.material_detail;
  delete _stock.created_by;
  delete _stock.updated_by;
  delete _stock.updated_on;
  delete _stock.created_on;
  _stock.qty = parseInt(_stock.qty + '');
  _stock.trim = parseFloat(_stock.trim + '');
  _stock.length = parseFloat(_stock.length + '');
  _stock.width = parseFloat(_stock.width + '');
  _stock.grain = parseInt(_stock.grain + '');
  const attachments = {};
  if (
    _stock.image_updated === true &&
    _stock.images &&
    _stock.images.length > 0
  ) {
    attachments.material_image = _stock.images[0];
  }
  delete _stock.images;
  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/csstock/update?propagation_type=${propagationType}&propagation_wo_id=${woId}`,
    data: {
      attachments: attachments,
      req_payload: _stock,
    },
  });
};

export const deleteWorkOrderMaterialStock = (stockId, woId) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'DELETE',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/csstock/id/${stockId}?propagation_wo_id=${woId}`,
    data: {},
  });
};

export const optimizeWorkOrder = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/cutsmart/optimize/${woId}`,
    data: {},
  });
};

export const getWoOptimizationStatus = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/cutsmart/status/${woId}`,
    data: {},
  });
};

export const getWoOptimizationsById = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/filter`,
    data: {
      page: 0,
      size: 100,
      req_payload: {
        deleted: [false],
        wo_id: [woId],
      },
    },
  });
};

export const updateCurrentOptimization = optimizationId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/select/${optimizationId}`,
    data: {},
  });
};

export const reOptimizeOptimizationPatterns = (
  optimizationId,
  patterns,
  stocks,
  boards,
) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  const uri = `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/reoptimize/${optimizationId}`;
  debugger;
  return service({
    method: 'PUT',
    url: uri,
    data: {
      req_payload: {
        stocks: stocks,
        patterns: patterns,
        boards: boards,
      },
    },
  });
};

const mapOptimizationResult = r => {
  const mats = r.wo_optimization_materials || [];
  const mappedMats = [];
  mats.forEach(m => {
    const def = m?.wo_material?.material_detail?.definition || {};
    const matType = def?.MATERIAL_TYPE?.value?.val || 'POSTLAM';
    const core = def?.CORE?.value?.val || '';
    const coreBrand = def?.CORE_BRAND?.value?.val || '';
    const skin1 = def?.SKIN_1?.value?.val || 'NO_SKIN';
    const skin2 = def?.SKIN_2?.value?.val || 'NO_SKIN';
    const skin1Brand = def?.SKIN_1_BRAND?.value?.val || '';
    const skin2Brand = def?.SKIN_2_BRAND?.value?.val || '';
    const mat = {
      matType: matType,
      material: m?.material_detail?.code,
      core: core,
      core_brand: coreBrand,
      skin_1: skin1,
      skin_2: skin2,
      skin_1_brand: skin1Brand,
      skin_2_brand: skin2Brand,
    };
    m.patterns.forEach(p => {
      mappedMats.push({
        ...mat,
        length: p.length,
        width: p.width,
        qty: p.board_count,
      });
    });
  });
  const coreMap = {};
  const skinMap = {};
  const addSkinToMap = (mat, skin, skinBrand) => {
    if (mat.matType !== 'POSTLAM') {
      return;
    }
    const skinKey =
      skin + '::' + skinBrand + '::' + mat.length + '::' + mat.width;
    if (!skinMap[`${skinKey}`]) {
      skinMap[`${skinKey}`] = {
        key: skinKey,
        skin: skin,
        skin_brand: skinBrand,
        length: mat.length,
        width: mat.width,
        qty: 0,
      };
    }
    skinMap[`${skinKey}`].qty += mat.qty;
  };
  mappedMats.forEach(m => {
    if (m.matType !== 'POSTLAM' && m.matType !== 'CORE') {
      return;
    }
    const coreKey =
      m.core + '::' + m.core_brand + '::' + m.length + '::' + m.width;
    if (!coreMap[`${coreKey}`]) {
      coreMap[`${coreKey}`] = {
        key: coreKey,
        core: m.core,
        core_brand: m.core_brand,
        length: m.length,
        width: m.width,
        qty: 0,
      };
    }
    coreMap[`${coreKey}`].qty += m.qty;
    addSkinToMap(m, m.skin_1, m.skin_1_brand);
    addSkinToMap(m, m.skin_2, m.skin_2_brand);
  });
  r.cores = Object.keys(coreMap).map(k => coreMap[`${k}`]);
  r.skins = Object.keys(skinMap).map(k => skinMap[`${k}`]);
  r.skins.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  r.cores.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  return r;
};

export const getWoOptimizationResultByWoId = async woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return await userNotFound();
  }
  const { org } = user;

  const result = await service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/results/woid/${woId}`,
    data: {},
  });
  if (result.status !== 200) {
    return result;
  }
  const payload = result.payload;
  result.payload = payload.map(mapOptimizationResult);
  return result;
};

export const getWoOptimizationResults = async (
  woId,
  selectedOnly,
  skipCache,
) => {
  const optimizationsResp = await getWoOptimizationsById(woId);
  if (optimizationsResp.status !== 200) {
    return optimizationsResp;
  }
  if (optimizationsResp.payload.length === 0) {
    return {
      status: 200,
      payload: [],
    };
  }
  const optimizationIds = optimizationsResp.payload
    .filter(val => !selectedOnly || val.selected === true)
    .map(val => val.uuid);
  const result = [];
  for (let i = 0; i < optimizationIds.length; i++) {
    const resp = await getWoOptimizationResultById(
      optimizationIds[`${i}`],
      skipCache,
    );
    if (resp.status !== 200) {
      return resp;
    }
    result.push(mapOptimizationResult(resp.payload));
  }
  return {
    status: 200,
    payload: result,
  };
};

export const getWoOptimizationResultById = (optimizationId, skipCache) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  const skipCacheParam = skipCache ? 'skip_cache=true' : '';
  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/results/id/${optimizationId}?${skipCacheParam}`,
    data: {},
  });
};

export const getWoOptimizationPtxById = (
  optimizationId,
  skipCache,
  materialSplit,
) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  const params = [];
  if (skipCache) {
    params.push('skip_cache=true');
  }
  if (materialSplit) {
    params.push('material_split=true');
  }
  const skipCacheParam = params.length > 0 ? params.join('&') : '';
  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/ptx/id/${optimizationId}?${skipCacheParam}`,
    data: {},
  });
};

export const generateOptimizationReport = (
  optimizationId,
  patternId,
  templateId,
  format,
) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  const query = `template_id=${templateId}&format=${
    format ? format : 'PDF'
  }&pattern_id=${patternId ? patternId : ''}`;
  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/patternreport/${optimizationId}?${query}`,
    data: {},
  });
};

export const generateOptimizationMaterialReport = (
  optimizationId,
  templateId,
  format,
) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  const query = `template_id=${templateId}&format=${format ? format : 'PDF'}`;
  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/matreport/${optimizationId}?${query}`,
    data: {},
  });
};

export const generateOptimizationPartLabels = optimizationId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/partlabel/${optimizationId}`,
    data: {},
  });
};

export const generateOptimizationOffCutLabelsByWoOptimizationId = (
  optimizationId,
  printerId,
  format,
) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  const reqParams = [];
  if (printerId) {
    reqParams.push('printer_id=' + printerId);
  }
  if (format) {
    reqParams.push('format=' + format);
  }
  const reqParamStr = reqParams.join('&');
  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wopattern/offcutlabel/optimization/${optimizationId}?${reqParamStr}`,
    data: {},
  });
};

export const printOffCutLabels = (
  offCutIds,
  printerId,
  format,
  fileName,
  batchSize,
) => {
  const user = getState().user;
  if (!user) {
    return userNotFound();
  }
  const { org, branch } = user;
  const req = {
    org_id: org.uuid,
    req_payload: {
      uuid: offCutIds,
    },
  };
  if (branch && branch.branch_id) {
    req.req_payload.branch_id = [branch.branch_id];
  }
  const reqParams = [];
  if (printerId) {
    reqParams.push('printer_id=' + printerId);
  }
  if (format) {
    reqParams.push('format=' + format);
  }
  if (batchSize && batchSize > 0) {
    reqParams.push('batch_size=' + batchSize);
  }
  if (fileName && fileName !== '') {
    reqParams.push('print_file_name=' + fileName);
  }
  const reqParamStr = reqParams.join('&');
  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wopattern/offcutlabel?${reqParamStr}`,
    data: req,
  });
};

export const getPatternPrintParts = patternId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wopattern/items/${patternId}`,
    data: {},
  });
};

export const getWorkOrderPending = () => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/pending`,
    data: {},
  });
};

export const updateWoStat = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/updatestat/${woId}`,
    data: {},
  });
};

export const syncWoStat = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorders/syncstat/${woId}`,
    data: {},
  });
};

export const replaceWoItemMaterial = (woId, updateMats) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/womat/replace/${woId}`,
    data: {
      req_payload: updateMats,
    },
  });
};

export const replaceWoItemEdge = (woId, updateEdges) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/woedge/replace/${woId}`,
    data: {
      req_payload: updateEdges,
    },
  });
};

export const getWoPatternBoard = boardId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wopatternboard/id/${boardId}`,
    data: {},
  });
};

export const setWoPatternComment = (boardId, comment) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;
  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wopattern/id/${boardId}/comment`,
    data: {
      req_payload: {
        comment: comment,
      },
    },
  });
};

export const swapWoItemDimension = (woId, material, length, width) => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorderitem/swapdimension/${woId}`,
    data: {
      req_payload: {
        material: material,
        length: length,
        width: width,
      },
    },
  });
};

export const getWorkOrderEdgeBands = woId => {
  const { user } = getState();
  if (!user || !user.org) {
    return userNotFound();
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/workorderitem/edgebands/${woId}`,
  });
};

export const updateBoardCount = (woId, req_payload) => {
  const { user } = getState();
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimizationmaterial/id/${woId}/requiredboards`,
    data: {
      req_payload,
    },
  });
};

export const storeMaterialCountsPerSize = (woId, req_payload) => {
  const { user } = getState();
  const { org } = user;
  return service({
    method: 'POST',
    url: `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/id/${woId}/metadata`,
    data: { req_payload },
  });
};

export const swapPart = (reqPayload, opId) => {
  const { user } = getState();
  const { org } = user;
  const uri = `/api/naadi-cutsmart-service/v1/naadi/cutsmart/${org.uuid}/wooptimization/reoptimize/${opId}/swap`;
  // debugger;
  return service({
    method: 'PUT',
    url: uri,
    data: {
      req_payload: reqPayload,
    },
  });
};
